<script setup>
import { computed } from "vue";
// import UIcon from "../UIcon/UIcon.vue";
// import ULoadingIcon from "../ULoadingIcon/ULoadingIcon.vue";

const props = defineProps({
  size: {
    type: String,
    validator(value) {
      return ["sm", "md", "lg"].includes(value);
    },
    default: "md",
  },
  variant: {
    type: String,
    validator(value) {
      return [
        "primary",
        "outline-primary",
        "outline-gray",
        "outline-white",
        "dark",
        "outline-gradient",
        "dark-outline-gray",
        "discord",
      ].includes(value);
    },
    default: "primary",
  },
  block: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  loadingIconType: {
    type: String,
    validator(value) {
      return ["border", "spinner"].includes(value);
    },
    default: "spinner",
  },
  icon: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  to: {
    type: String,
    default: "",
  },
  isExternalLink: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["click"]);

const classList = computed(() => {
  const classes = Object.entries({
    variant: props.variant,
    size: props.size,
  })
    .filter(([, value]) => value)
    .map(([key, value]) => `u-button--${key}-${value}`);

  classes.push({
    "u-button d-inline-flex align-items-center justify-content-center": true,
    "d-flex w-100": props.block,
    "opacity-25 cursor-not-allowed": props.disabled,
  });

  return classes;
});

const iconSize = computed(() => {
  switch (props.size) {
    case "sm":
      return 16;
    case "md":
      return 20;
    case "lg":
      return 26;
    default:
      return "16";
  }
});

const componentTag = computed(() => {
  if (props.to && props.isExternalLink === false) {
    return "router-link";
  } else if (props.to && props.isExternalLink === true) {
    return "a";
  }
  return "button";
});

const attrs = computed(() => {
  const attrs = {
    class: classList.value,
    type: props.to ? "button" : "submit",
  };

  if (props.disabled || props.loading) {
    attrs.disabled = "disabled";
  }
  if (props.to && props.isExternalLink === false) {
    attrs.to = props.to;
  } else if (props.to && props.isExternalLink === true) {
    attrs.href = props.to;
  }

  return attrs;
});
</script>

<template>
  <component :is="componentTag" v-bind="attrs" @click="emit('click')">
    <!-- <u-icon
      v-if="icon"
      class="u-button__icon me-1"
      :name="icon"
      :size="iconSize"
    /> -->
    <span
      class="u-button__content d-flex align-items-center justify-content-center"
    >
      <slot></slot>
    </span>

    <!-- <u-loading-icon
      v-if="loading"
      class="u-button__loading ms-2 flex-shrink-0"
      :size="size"
      :type="loadingIconType"
      inline
    /> -->
  </component>
</template>

<style lang="scss">
.u-button {
  font-family: var(--font-family-title);
  font-weight: var(--font-weight-button);
  letter-spacing: var(--letter-spacing-button);
  border: 0;
  border: 1px solid transparent;
  cursor: pointer;
  line-height: 1;
  box-shadow: 0px 4px 12px rgba(20, 19, 25, 0.16);
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  &--variant-primary {
    color: white;
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    transition: all 0.2s ease-in-out;

    // &:hover {
    //   background-color: rgba(var(--color-primary-rgb), 0.8);
    //   border-color: rgba(var(--color-primary-rgb), 0.8);
    // }

    // &:active {
    //   background-color: rgba(var(--color-primary-rgb), 0.9);
    //   border-color: rgba(var(--color-primary-rgb), 0.9);
    // }
  }

  &--variant-discord {
    color: #fff;
    background-color: #7289da;
    border-color: var(--color-dark-gray);
    transition: all 0.2s ease-in-out;

    // &:hover {
    //   background-color: rgba(var(--color-primary-rgb), 0.8);
    //   border-color: rgba(var(--color-primary-rgb), 0.8);
    // }

    // &:active {
    //   background-color: rgba(var(--color-primary-rgb), 0.9);
    //   border-color: rgba(var(--color-primary-rgb), 0.9);
    // }
  }

  &--variant-outline-primary {
    background-color: transparent;
    box-shadow: 0px 4px 12px rgba(20, 19, 25, 0.16);
    border-color: var(--color-primary);
    color: var(--color-white);
    transition: all 0.2s ease-in-out;

    // &:hover {
    //   background-color: var(--color-primary);
    // }
  }

  &--variant-outline-white {
    background-color: transparent;
    border-color: var(--color-white);
    color: var(--color-white);
  }
  &--variant-outline-gray {
    background-color: transparent;
    border-color: var(--color-dark-gray);
    color: var(--color-white);
  }
  &--variant-outline-gray {
    background-color: transparent;
    border-color: var(--color-dark-gray);
    color: var(--color-white);
  }
  &--variant-dark {
    background-color: #242642;
    border-color: #242642;
    color: var(--color-white);
    transition: all 0.2s ease-in-out;

    // &:hover {
    //   background-color: var(--color-primary);
    // }
  }
  &--variant-dark-outline-gray {
    background-color: #393f5f;
    border: 2px solid var(--color-mid-gray);
  }

  &--variant-outline-gradient {
    position: relative;
    color: var(--color-white);
    box-shadow: 0px 4px 16px rgba(246, 0, 245, 0.24);
    transition: all 0.2s ease-in-out;

    &:before,
    &:after {
      content: "";
      position: absolute;
      display: block;
    }

    &:before {
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      top: -2px;
      left: -2px;
      background: radial-gradient(circle at 0%, #f600f5, #00ffaa);
      z-index: 1;
      border-radius: 13px;
    }

    &::after {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: #393f5f;
      z-index: 2;
      border-radius: 12px;
    }

    * {
      position: relative;
      z-index: 3;
    }

    &[disabled="disabled"],
    &:disabled {
      opacity: 1 !important;
      color: var(--color-mid-gray);
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);

      &:before {
        background: #3b394e;
      }
      &::after {
        background: #2b293f;
      }
    }
  }

  &[disabled="disabled"],
  &:disabled {
    cursor: not-allowed;
  }

  &--size-sm {
    font-size: calc(var(--font-size-button) * 0.75);
    padding: 10px 16px;
    border-radius: 9px;
  }

  &--size-md {
    font-size: var(--font-size-button);
    padding: 10px 16px;
    border-radius: 12px;

    @include mq(tablet-wide) {
      padding: 13px 24px;
    }
  }

  &--size-lg {
    font-size: calc(var(--font-size-button) * 1.5);
    padding: 21px 32px;
    border-radius: 15px;
  }

  &--block {
    display: block;
    width: 100%;
  }
}
</style>
