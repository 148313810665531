<template>
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 58.2609C0 72.8709 0 80.176 2.84331 85.7563C5.34435 90.6648 9.33515 94.6556 14.2437 97.1567C19.824 100 27.1291 100 41.7391 100H58.2609C72.8709 100 80.176 100 85.7563 97.1567C90.6648 94.6556 94.6556 90.6648 97.1567 85.7563C100 80.176 100 72.8709 100 58.2609V41.7391C100 27.1291 100 19.824 97.1567 14.2437C94.6556 9.33515 90.6648 5.34435 85.7563 2.84331C80.176 0 72.8709 0 58.2609 0H41.7391C27.1291 0 19.824 0 14.2437 2.84331C9.33515 5.34435 5.34435 9.33515 2.84331 14.2437C0 19.824 0 27.1291 0 41.7391V58.2609Z"
      fill="url(#paint0_linear_10_271)"
    />
    <rect
      width="60"
      height="60"
      transform="translate(20.0002 20)"
      fill="url(#paint1_linear_10_271)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M75.6352 48.0626C75.6969 48.4553 75.7175 48.8539 75.7189 49.2525L75.7307 53.2983C75.7307 56.717 74.2936 78.8555 49.6568 79.2453C35.4906 79.4695 29.0475 71.6167 26.1293 64.901C24.5159 61.1863 23.7372 57.1654 23.7504 53.1181L23.8312 28.7874C23.84 25.8831 25.3755 23.1575 27.9263 21.7523C29.0842 21.1134 30.4169 20.75 31.8349 20.75C33.2513 20.75 34.584 21.1134 35.7419 21.7508C38.2913 23.1561 39.8253 25.8802 39.8356 28.7845C39.8356 28.7845 39.8649 47.5512 39.9237 53.8068L39.9239 53.8302C39.933 54.9052 39.999 62.7297 49.504 62.9696C59.8953 63.2305 59.3737 53.8068 59.3737 53.8068V48.9111V48.8481C59.6999 44.6952 63.1691 41.2326 67.6389 41.2326C71.6841 41.2326 75.0371 44.1941 75.6352 48.0626ZM75.7307 28.9427C75.7307 33.4675 72.0527 37.1355 67.5155 37.1355C62.9784 37.1355 59.3003 33.4675 59.3003 28.9427C59.3003 24.418 62.9784 20.75 67.5155 20.75C72.0527 20.75 75.7307 24.418 75.7307 28.9427Z"
      fill="url(#paint2_linear_10_271)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_10_271"
        x1="0.0633093"
        y1="0.126622"
        x2="0.0633093"
        y2="100"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.000211149" stop-color="#24204B" />
        <stop offset="1" stop-color="#17152F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_10_271"
        x1="0.0379856"
        y1="0.0759734"
        x2="0.0379856"
        y2="60"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.000211149" stop-color="#24204B" />
        <stop offset="1" stop-color="#17152F" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_10_271"
        x1="69.9412"
        y1="27.1928"
        x2="34.4285"
        y2="76.7151"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F416A8" />
        <stop offset="0.81119" stop-color="#AD16F4" />
      </linearGradient>
    </defs>
  </svg>
</template>